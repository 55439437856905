import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import heroImage from '../../../assets/images/Picture.png'; // Replace with the path to your image
import heroImage1 from '../../../assets/images/Picture2.png';
import heroImage3 from '../../../assets/images/Picture3.png';
import heroImage4 from '../../../assets/images/Picture4.png';
import heroImage5 from '../../../assets/images/Picture5.png';

const HeroSections = () => {
  return (
    <Box>
    <Box style={{marginLeft:"21px"}}>
      
    <Typography variant="h4" component="h2" gutterBottom >
    Our Portfolio
  </Typography>
  </Box>
      <Box
        sx={{
          marginTop: "23px",
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, 
          alignItems: 'center',
          justifyContent: 'space-between',
          width: "100%"
        }}
      >
    
        <Box
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: 'left'
          }}
        >
        
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: "bold" }} color="primary">
            Our Tech Space
          </Typography>
          <Typography variant="body1" gutterBottom>
          Saeedan Technology is a leading software development company located in Lahore, Pakistan. We are committed to developing innovative software solutions for leading enterprises worldwide.
          </Typography>
          <Button variant="contained" color="primary">
            Learn More
          </Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={heroImage}
            alt="Hero"
            style={{
              maxWidth: '100%',
              height: '350px'
            }}
          />
        </Box>
      </Box>

      {/* Section 2 */}
      {/* <Box
        sx={{
          marginTop: "23px",
        marginLeft: { md: "14px", sm: "0px" },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Column on small screens, row on medium and above
          alignItems: 'center',
          justifyContent: 'space-between',
          width: "98%"
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={heroImage1}
            alt="Hero"
            style={{
              maxWidth: '100%',
              height: '350px'
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: 'left'
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: "bold" }} color="primary">
            Mango Home Websites
          </Typography>
          <Typography variant="body1" gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </Typography>
          <Box sx={{ padding: "7px" }}>
            <a href='https://saeedantechpvt.com/'>https://saeedantechpvt.com/</a>
          </Box>
          <Button variant="contained" color="primary">
            Learn More
          </Button>
        </Box>
      </Box> */}

      {/* Section 3 */}
      {/* <Box
        sx={{
          marginTop: "23px",
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Column on small screens, row on medium and above
          alignItems: 'center',
          justifyContent: 'space-between',
          width: "97%"
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: 'left'
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: "bold" }} color="primary">
            Our Tech Space
          </Typography>
          <Typography variant="body1" gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </Typography>
          <Button variant="contained" color="primary">
            Learn More
          </Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={heroImage3}
            alt="Hero"
            style={{
              maxWidth: '100%',
              height: '350px'
            }}
          />
        </Box>
      </Box> */}

      {/* Section 4 */}
      {/* <Box
        sx={{
          marginTop: "23px",
        marginLeft: { md: "-34px", sm: "10px" },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Column on small screens, row on medium and above
          alignItems: 'center',
          justifyContent: 'space-between',
          width: "98%"
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={heroImage5}
            alt="Hero"
            style={{
              maxWidth: '100%',
              height: '350px'
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: '20px',
            textAlign: 'left'
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: "bold" }} color="primary">
            Mango Home Websites
          </Typography>
          <Typography variant="body1" gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </Typography>
          <Box sx={{ padding: "7px" }}>
            <a href='https://saeedantechpvt.com/'>https://saeedantechpvt.com/</a>
          </Box>
          <Button variant="contained" color="primary">
            Learn More
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default HeroSections;
