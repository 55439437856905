import React from "react";
import { AppBar, Box, Typography, Toolbar } from "@mui/material";
import logo from "../../../assets/images/Logo.webp";
import Complogo from "../../../assets/images/companylogo.jfif";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <AppBar position="static" sx={{ boxShadow: "none" }}>
      <Toolbar
        sx={{
          justifyContent: "space-between",
          padding: { xs: "10px", sm: "10px 20px" },
        }}
      >
        <Link to="/">
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ height: { xs: 40, sm: 60 } }}
          />
        </Link>
        <Typography
          variant="h6"
          color="white"
          sx={{ ml: { xs: 1, sm: 4 }, fontSize: { xs: "16px", sm: "20px" } }}
        >
          Projects Portfolio
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
