import axios from 'axios';

export const ShowPortfolio = async () => {
  try {
    const response = await axios.get('https://test.saeedantechpvt.com/api/showPortfolio');

    // Axios automatically parses the JSON, so we can directly return the response data
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolios:', error);
    return { success: false, message: error.message, payload: [] };
  }
};
