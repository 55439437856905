import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, Typography, CircularProgress, Grid } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import FAQSection from "./FAQSection";
import CommentsSection from "./CommentsSection";

const ProjectDetails = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { item } = location.state || {};

  useEffect(() => {
    if (!item) {
      console.log("No project details available.");
      setLoading(false);
    } else {
      console.log("Project details:", item);
      setLoading(false);
    }
  }, [item]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!item) {
    return <div>No project details available.</div>;
  }
  console.log(item, "imageUrl");
  return (
    <Box>
      <Header />
      <Box sx={{ padding: "20px", maxWidth: 800, margin: "0 auto" }}>
        <Typography variant="h4" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          {item.title}
        </Typography>
        <Box
          component="img"
          src={item.image}
          alt={item.title}
          sx={{
            width: "100%",
            height: "350px",
            borderRadius: "8px",
            boxShadow: 3,
          }}
        />
        <Typography variant="h6" sx={{ marginTop: 1 }}>
          Description:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>{item.description}</Typography>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Additional Images:</Typography>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component="img"
                src={item.image}
                alt="additional-0"
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  boxShadow: 1,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box
                component="img"
                src={item.image}
                alt="additional-1"
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  boxShadow: 1,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box
                component="img"
                src={item.image}
                alt="additional-2"
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  boxShadow: 1,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <FAQSection />
        <CommentsSection />
      </Box>
      <Footer />
    </Box>
  );
};

export default ProjectDetails;
