import React from "react";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import StorageIcon from "@mui/icons-material/Storage";
import SecurityIcon from "@mui/icons-material/Security";
import CodeIcon from "@mui/icons-material/Code";

const services = [
  {
    name: "Web Development",
    category: "Development",
    icon: <WebIcon />,
    subcategories: ["React", "PHP", "Laravel", "JavaScript", "MySQL"],
  },
  {
    name: "Mobile Development",
    category: "Development",
    icon: <MobileFriendlyIcon />,
    subcategories: ["iOS", "Android", "Flutter", "React Native"],
  },
  {
    name: "Cloud Storage",
    category: "Storage",
    icon: <StorageIcon />,
    subcategories: ["AWS", "Google Cloud", "Azure"],
  },
  {
    name: "Cyber Security",
    category: "Security",
    icon: <SecurityIcon />,
    subcategories: [
      "Penetration Testing",
      "Network Security",
      "Data Protection",
    ],
  },
];

const OurServices = () => {
  return (
    <Box
     sx={{textAlign:"center"}}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Our Services
      </Typography>
      <Grid container spacing={4} sx={{ width: "100%" }}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                height: "100%",
                backgroundColor: "#f5f5f5",
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "white",
                  "& .category-text": {
                    color: "white",
                  },
                  "& .subcategory-text": {
                    color: "white",
                  },
                },
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Box mr={2}>{service.icon}</Box>
                  <Typography variant="h6" component="h4">
                    {service.name}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    ml={1}
                    className="category-text"
                  >
                    {service.category}
                  </Typography>
                </Box>
                <Box mt={2}>
                  {service.subcategories.map((subcategory, subIndex) => (
                    <Box display="flex" alignItems="center" key={subIndex}>
                      <CodeIcon sx={{ marginRight: 1 }} />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="subcategory-text"
                      >
                        {subcategory}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurServices;
