import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper } from '@mui/material';

const CommentsSection = () => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');

  const handleCommentSubmit = () => {
    if (commentText.trim() && name.trim() && email.trim()) {
      const newComment = {
        name,
        email,
        website,
        text: commentText,
      };
      setComments([...comments, newComment]);
      setCommentText('');
      setName('');
      setEmail('');
      setWebsite('');
    }
  };

  return (
    <Box sx={{ marginTop: 4, padding: 2 }}>
      <Typography variant="h4" sx={{ textAlign: 'left', marginBottom: 2 }}>
        Comments
      </Typography>
      <Box elevation={3} sx={{ padding: 2 }}>
        <TextField
          label="Name (required)"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Email (will not be published) (required)"
          fullWidth
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Website"
          fullWidth
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Leave a comment"
          multiline
          rows={4}
          fullWidth
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" onClick={handleCommentSubmit}>
          Your Comments
        </Button>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        {comments.map((comment, index) => (
          <Paper key={index} elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {comment.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {comment.email} {comment.website && `| ${comment.website}`}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {comment.text}
            </Typography>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default CommentsSection;
