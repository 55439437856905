import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'What is your refund policy?',
    answer: 'Our refund policy is...',
  },
  {
    question: 'How can I contact support?',
    answer: 'You can contact support via...',
  },
];

const FAQSection = () => {
  return (
    <Box sx={{ marginTop: 4, width: "100%" }}>
    <Typography variant="h3" sx={{ textAlign: 'left', fontSize: { xs: '1.5rem', sm: '2rem' } }}>
      FAQ
    </Typography>
    {faqs.map((faq, index) => (
      <Accordion key={index} style={{ background: "none", boxShadow: "none" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: { xs: '8px', sm: '16px' } }}>
          <Typography variant="h4" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
            {faq.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: { xs: '8px', sm: '16px' } }}>
          <Typography variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
            {faq.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
  );
};

export default FAQSection;
