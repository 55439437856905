import React from 'react';
import { Box, Typography, Grid , IconButton} from '@mui/material';
import logo from '../../../assets/images/Logo.webp'; // Replace with your logo image

import { Link } from "react-router-dom";

import Complogo from "../../../assets/images/companylogo.jfif";
import HeroSections from "./HeroSections";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const Footer = () => {
  return (
    <Grid item xs={12}>
          <Box
            className="footer"
            sx={{
              backgroundColor: "#f8f8f8",
              padding: "20px 0",
              borderTop: "1px solid #ddd",
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: { xs: 2, sm: 4, md: 6 } }}
            >
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  mb: { xs: 3, md: 0 },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", fontSize: "18px" }}
                  color="primary"
                >
                  Explore
                </Typography>
                <Typography variant="body2">
                  Services | Team | Company | Portfolio | Careers | Blog
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{ textAlign: "center", mb: { xs: 3, md: 0 } }}
              >
               <Link to="/">
                <img
                  src={Complogo}
                  alt="Saeedan Technology Logo"
                  style={{ height: 50, marginRight: 2, width: "100px" }}
                />
                </Link>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  textAlign: { xs: "center", md: "right" },
                  mb: { xs: 3, md: 0 },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", fontSize: "18px" }}
                  color="primary"
                >
                  Contact Us
                </Typography>
                <Typography variant="body2">Have a Question?</Typography>
                <Typography variant="body2">+92 300 7861566</Typography>
                <Typography variant="body2">
                  contact@saeedantechpvt.com
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ marginTop: "20px", textAlign: "center" }}>
              <IconButton
                aria-label="Facebook"
                href="https://facebook.com"
                target="_blank"
                sx={{ color: "#3b5998" }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                aria-label="Twitter"
                href="https://twitter.com"
                target="_blank"
                sx={{ color: "#1da1f2" }}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                aria-label="LinkedIn"
                href="https://linkedin.com"
                target="_blank"
                sx={{ color: "#0077b5" }}
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
  );
};

export default Footer;
