import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Grid,
  Button,
  Pagination,
  Tabs,
  Tab,
} from "@mui/material";

import { Link } from "react-router-dom";
import logo from "../../../assets/images/Logo.webp";
import Complogo from "../../../assets/images/companylogo.jfif";
import HeroSections from "./HeroSections";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate, useLocation } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OurServices from "./OurServices";
import Footer from "./Footer";
import Header from "./Header";
import FAQSection from "./FAQSection";
import CommentsSection from "./CommentsSection";
import innvoImage from "../../../assets/images/innvoimage.PNG";
import tyerImage from "../../../assets/images/tyerwebsiteimage.PNG";
import evergreen from "../../../assets/images/evergreenimage.PNG";
import vediolearningimage from "../../../assets/images/vediolearning.PNG";
import starimage from "../../../assets/images/100starimage.PNG";
import shekinahi from "../../../assets/images/shekinahimage.PNG";
import ptelabimage from "../../../assets/images/ptelab.PNG";
import expoimage from "../../../assets/images/expo.PNG";
import tredingApplications from "../../../assets/images/tradingApp.PNG";
import dubaisafariimage from "../../../assets/images/dubaisafari.PNG";
import companywebsiteimage from "../../../assets/images/companyWebsites.PNG";
import smartservicesimage from "../../../assets/images/smartservices.PNG";
import hostinigerimage from "../../../assets/images/hostiniger.PNG";

import Hiigsikaalimage from "../../../assets/images/Hiigsikaal.PNG";
import mangohomeimage from "../../../assets/images/mangohome.PNG";
import crmpockerimage from "../../../assets/images/crmpocker.png";
import PortfolioProject from '../../componenets/LandingPage/PortfolioProject'
const projects = {
  web: [
    {
      id: 1,
      title: "Innovancia,votre partenaire pour un financement sur mesure",
      description:
        "Innovancia est un cabinet de conseil créé par deux passionnés de la recherche scientifique, avec plus de 10 ans d'expérience dans le financement de l'innovation. Nous sommes spécialisés dans l'accompagnement des entreprises pour optimiser et sécuriser leurs dispositifs de financement dans les domaines de la R&D, de l'innovation et de l'investissement industriel.Forts de notre expertise, nous travaillons main dans la main avec nos clients pour maximiser leurs opportunités de financement, tout en assurant une conformité rigoureuse aux exigences fiscales et administratives. Grâce à une équipe d'experts qualifiés, nous intervenons également lors des phases de contrôle des administrations, garantissant ainsi une sécurité totale dans la gestion des projets.Chez Innovancia, notre engagement est de soutenir les organisations dans la réalisation de leurs ambitions innovantes, tout en contribuant à un avenir durable et prospère.",
      image: innvoImage,
      link: "/project-details",
    },
    {
      id: 2,
      title: "Cheap tires and rims online",
      description:
        "With us at DÄCK365, you will always find cheap tires and rims that fit your car. We have a large selection of affordable summer tires and winter tires from the biggest tire manufacturers.",
      image: tyerImage,
      link: "/project2",
    },
    {
      id: 3,
      title: "Ever Green Full-Service Electrical Solutions For Today's Demands",
      description:
        "From electrical stations to EV chargers and beyond, we power your future with seamless installations and reliable maintenance.",
      image: evergreen,
      link: "/project3",
    },

    {
      id: 4,
      title: "Course Vedio Learning Websites",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: vediolearningimage,
      link: "/project1",
    },
    {
      id: 5,
      title: "100 Star",
      description:
        "SUCHE UND BESUCHE DEINE LEUCHTENDEN STARS UND GEWINNE HANDY GUTHABEN FÜR 1 JAHR ODER 2 JAHRE INCLUSIVE UNLIMITIERTEN DATENVOLUMEN ODER 10.000 €, EINFACH FAN WERDEN STARS BESUCHEN UND GEWINNEN!",
      image: starimage,
      link: "/project2",
    },
    {
      id: 6,
      title: "shekinah",
      description:
        "Discover the heartwarming fellowship at Shekinah Haitian SDA Church located right in your neighborhood. Welcoming you with open arms and sincere smiles, we foster a sense of belonging. Join us today for a transformative worship experience!",
      image: shekinahi,
      link: "/project3",
    },

    {
      id: 7,
      title: "PTE Lab",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: ptelabimage,
      link: "/project1",
    },
    {
      id: 8,
      title: "Expo the tattoo judeging software",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: expoimage,
      link: "/project2",
    },
    {
      id: 9,
      title: "Trading Applications",
      description:
        "The Trending App is a platform designed to aggregate and display the most popular topics, products, or content across various domains, such as social media, news, and e-commerce. Users can easily navigate through different trending categories like technology, entertainment, fashion, and more. The app features a real-time data feed that ensures users always stay updated with the latest trends. It also allows personalized trending recommendations based on user preferences, making the experience tailored and relevant.",
      image: tredingApplications,
      link: "/project3",
    },

    {
      id: 10,
      title: "Dubai Safari",
      description:
        "Choose from our curated selection of activities, including desert tours, adventures, city tours, yacht cruises, and water adventures. Immerse yourself in the best experiences, tailored for both tourists and residents.The Dubai Safari Project is a comprehensive web or mobile application designed to offer a seamless digital experience for users planning or exploring the renowned Dubai Safari Park. The project integrates various features to enhance user interaction, provide informative content, and streamline the booking and exploration process. The app targets both local and international visitors, offering detailed information about the park’s attractions, events, wildlife, and ticketing options.",
      image: dubaisafariimage,
      link: "/project3",
    },
    {
      id: 11,
      title: "Email Markeeting",
      description:
        "Sparkamis integrates email, chat, file sharing, video conferencing, and task management seamlessly, empowering teams to collaborate effortlessly and enhance productivity in a unified communication platform.The Email Project is a comprehensive platform designed to help businesses create, manage, and execute effective email marketing campaigns. This project is aimed at small, medium, and large businesses looking to enhance customer engagement, improve lead generation, and boost sales through targeted email communications. The platform offers a range of tools to design visually appealing emails, segment audiences, automate workflows, and track performance metrics.",
      image: companywebsiteimage,
      link: "/project3",
    },
    {
      id: 12,
      title: "Smart Services Business",
      description:
        "The Smart Services Business Project is a platform that offers innovative, technology-driven solutions for businesses, aiming to automate processes, improve efficiency, and enhance customer experience. The project focuses on integrating advanced technologies such as artificial intelligence (AI), the Internet of Things (IoT), and data analytics to provide businesses with smart services that are scalable and customizable according to their specific needs.This platform is designed for a wide range of industries including retail, hospitality, healthcare, and logistics. It offers solutions like automated customer service, real-time analytics, predictive maintenance, and digital transformation consulting.",
      image: smartservicesimage,
      link: "/project3",
    },
    {
      id: 13,
      title: "Hostinger Services",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: hostinigerimage,
      link: "/project3",
    },
    {
      id: 14,
      title: "Hiigsikaali",
      description:
        "Hiigsikaal is start-up Sami-tech local business that focuses on developing and bringing innovative technology-based products to serve communities & respond local market needs.Hiigsikaal is R&D Platform designed as a Knowledge Hub and localizes development solutions for least developed communities, produce a range of materials on various thematic issues related to SDG goals, engage multi-stakeholders to identify a variety of innovative practices, coping means and will facilitate the exchange of experience and provide learning resources on emerging issues across many spheres, including nutrition, food, water, transportation, energy, climate change and environment challenges.Hiigsikaal engage multi-stakeholders to enhance voluntary partnerships for sustainable development goals as important vehicles for mobilizing stakeholders and sharing knowledge, expertise, technologies to accelerate support the achievement of the sustainable development goals in Horn of Africa.",
      image: Hiigsikaalimage,
      link: "/project3",
    },
    {
      id: 15,
      title: "Rentel Projects",
      description:
        "The Rental Platform is a comprehensive web application designed to streamline the process of renting properties for landlords, property managers, and tenants. The platform provides an easy-to-use interface for listing properties, managing tenant applications, handling payments, and maintaining detailed property records. It simplifies property rentals by integrating automation, communication tools, and payment processing into one centralized system.",
      image: mangohomeimage,
      link: "/project3",
    },

    {
      id: 16,
      title: "CRM Pocker",
      description:
        "The Poker CRM System is a specialized Customer Relationship Management platform designed for managing poker events, tournaments, and player data. The system helps poker event organizers and casino operators manage their relationships with players by centralizing all player-related data, tracking tournament history, and automating customer engagement processes. The platform focuses on enhancing player experience, maintaining tournament records, and building a comprehensive player database.",
      image: crmpockerimage,
      link: "/project3",
    },
  ],
  mobile: [
    {
      id: 4,
      title: "Mobile Project One",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: "https://via.placeholder.com/400",
      link: "/project4",
    },
    {
      id: 5,
      title: "Mobile Project Two",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: "https://via.placeholder.com/400",
      link: "/project5",
    },
    {
      id: 6,
      title: "Mobile Project Three",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: "https://via.placeholder.com/400",
      link: "/project6",
    },
    {
      id: 7,
      title: "Mobile Project Three",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean sollicitudin",
      image: "https://via.placeholder.com/400",
      link: "/project6",
    },
  ],
};

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 8;
  const [value, setValue] = useState(0);

  const projectsRef = useRef(null);
  const mobileAppRef = useRef(null);
  useEffect(() => {
    if (location.pathname === "/web-developments") {
      setActiveTab(0);
      if (projectsRef.current) {
        projectsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.pathname === "/mobile-app") {
      setActiveTab(1);
      if (mobileAppRef.current) {
        mobileAppRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/web-developments");
    } else if (newValue === 1) {
      navigate("/mobile-app");
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const currentProjects =
    activeTab === 0
      ? projects.web.slice(
          (currentPage - 1) * projectsPerPage,
          currentPage * projectsPerPage
        )
      : projects.mobile.slice(
          (currentPage - 1) * projectsPerPage,
          currentPage * projectsPerPage
        );

  const totalPages =
    activeTab === 0
      ? Math.ceil(projects.web.length / projectsPerPage)
      : Math.ceil(projects.mobile.length / projectsPerPage);

  const handleNavigate = (project) => {
    navigate("/project-details", { state: { item: project } });
  };

  return (
    <Box sx={{ flex: 1, backgroundColor: "#f0f0f0" }}>
      <Header />
      <Grid container spacing={3} sx={{ padding: { xs: "20px", sm: "40px" } }}>
        <Grid item xs={12} sm={12} md={12}>
          <HeroSections />
        </Grid>
        {/*  
        <Grid item xs={12} sm={12} md={12}>
          <OurServices />
        </Grid>
*/}
        {/* <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="h4"
            color="black"
            gutterBottom
            ref={projectsRef}
            sx={{
              marginBottom: "10px",
              fontSize: { xs: "24px", sm: "32px" },
              textAlign: "center",
            }}
          >
            Our Projects
          </Typography>

          <Tabs
            value={activeTab}
            onChange={handleChange}
            centered
            style={{ margin: "12px" }}
            ref={mobileAppRef}
          >
            <Tab label="Web Development" />
            <Tab label="Mobile App Development" />
          </Tabs>

          <Grid
            container
            spacing={2}
            sx={{
              padding: { xs: "10px", sm: "20px", md: "40px" },
              width: "100%",
              marginLeft: { xs: "0px", sm: "0px", md: "-12px" },
            }}
          >
            {currentProjects.map((project) => (
              <Grid item xs={12} sm={6} md={3} key={project.id}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    height: "400px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={project.image}
                    alt={project.title}
                    sx={{ objectFit: "cover" }}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: { xs: "16px", sm: "18px", md: "20px" },
                          textAlign: "center",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          lineHeight: "23px",
                          textAlign: "left",
                          overflow: "hidden",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {project.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        paragraph
                        sx={{
                          fontSize: { xs: "14px", sm: "16px" },
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 3,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {project.description}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNavigate(project)}
                      fullWidth
                      sx={{
                        fontSize: { xs: "12px", sm: "14px" },
                        marginTop: "10px",
                      }}
                    >
                      View Project
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Grid> */}
        <PortfolioProject/>
      </Grid>

      <Footer />
    </Box>
  );
};

export default LandingPage;
