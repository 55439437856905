import { useEffect, useState } from "react";
import React from "react";
import { ShowPortfolio } from "../../../redux/ShowPostfolio";

const LatestProjects = React.memo(({ onFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [portFolios, setPortFolios] = useState([]);
  const [filteredPortfolios, setFilteredPortfolios] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const categories = ["All", "MOBILE APP", "WEB APP", "CRM"];

  const getAllPortfoliosProjects = () => {
    setIsLoading(true);
  
    ShowPortfolio()
      .then((res) => {
        const { payload } = res;
  
        setPortFolios(payload);
        setFilteredPortfolios(payload); 
        onFetch(payload);
      })
      .catch((error) => {
        console.error("Error fetching portfolios:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Filter projects based on selected category
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to the first page when category changes

    if (category === "All") {
      setFilteredPortfolios(portFolios); // Show all projects if "All" is selected
    } else {
      // Filter projects by category
      const filtered = portFolios.filter(
        (portfolio) => portfolio.category === category
      );
      setFilteredPortfolios(filtered);
    }
  };

  // Pagination logic to display only the current page's items
  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = filteredPortfolios.slice(indexOfFirstProject, indexOfLastProject);

  // Handle page change (left and right arrows)
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredPortfolios.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    getAllPortfoliosProjects();
  }, []); // Ensure this only runs once when the component mounts

  const totalProjects = filteredPortfolios.length;
  const totalPages = Math.ceil(totalProjects / itemsPerPage);

  return (
    <div className="flex flex-col items-center justify-center gap-6 xl:gap-10 p-10 md:p-16 xl:p-20">
      <h1 className="text-primary text-xl md:text-2xl lg:text-3xl font-bold">
        Our Latest Projects
      </h1>

      {/* Tabs for category selection */}
      <div className="flex gap-5 mb-10 border-b border-gray-200">
        {categories.map((category) => (
          <button
            key={category}
            className={`pb-2 text-lg font-medium transition duration-300 border-b-2  ${selectedCategory === category
              ? "border-primary text-blue-900"
              : "border-transparent text-gray-500"
              } hover:text-primary`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        {isLoading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow duration-500 transition-all animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
          ))
        ) : currentProjects.length > 0 ? (
          currentProjects.map((portFolio) => (
            <div
              key={portFolio.id}
              className="flex flex-col bg-white border-[1.5px] border-problemBorder rounded-lg duration-500 transition-all"
              style={{ opacity: 0.9, transition: "opacity 0.8s ease-in-out" }}
            >
              {/* image */}
              <img
                src={`https://test.saeedantechpvt.com/${portFolio.first_content_image}`}
                alt={portFolio.portfolio_title || "Portfolio Image"}
                className="w-full h-full object-cover rounded-lg"
              />

              {/* title and description */}
              <div className="flex flex-col items-start justify-between p-5 gap-2">
                <h1 className="font-semibold text-lg md:text-xl lg:text-2xl">
                  {portFolio?.portfolio_title}
                </h1>
                <p className="text-md md:text-[1rem] text-black/55">
                  {portFolio?.portfolio_description}
                </p>
              </div>

              {/* button */}
              <div className="p-5 flex items-center justify-between">
                <a
                  className="text-white bg-blue-900 rounded-md px-5 py-2 max-w-max"
                  href={`https://test.saeedantechpvt.com/${portFolio.banner_image}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Look it up
                </a>

                {portFolio?.project_url && (
                  <a
                    className="text-white bg-blue-900 rounded-md px-5 py-2 max-w-max"
                    href={portFolio.project_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    View Project
                  </a>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No projects available for the selected category.</p>
        )}
      </div>

      {/* Pagination with Arrows */}
      <div className="flex justify-between w-full mt-6 items-center">
        <div className="text-lg">
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
        </div>

        <div className="flex gap-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-2 text-white bg-gray-500 rounded disabled:opacity-50"
          >
            <span className="material-icons">Back</span>
          </button>
          
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-white bg-gray-500 rounded disabled:opacity-50"
          >
            <span className="material-icons">Next</span>
          </button>
        </div>
      </div>

     
    </div>
  );
});

export default LatestProjects;
